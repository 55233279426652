<template>
  <div id="app">

    <div class="photos">
      <Photo
      v-for="(photo, index) in photos"
      :key="index"
      :photo="photo"
      :clickCallback="() => openLightbox(index)"
      />
    </div>

    <div
      class="lightbox"
      :class="{
        active: lightboxIndex > -1,
      }"
      @click.self="closeLightbox"
    >
      <div class="wrapper">
        <div
          class="prev"
          @click="() => changeImage(-1)"
        />
        <div
          class="current"
          @click="closeLightbox"
        >
          <img
            v-if="lightboxIndex > -1"
            :src="photos[lightboxIndex]"
            alt=""
          >
        </div>
        <div
          class="next"
          @click="() => changeImage(1)"
        />
      </div>
    </div>

    <div class="fader" />
  </div>
</template>

<script>
  import Photo from '@/components/Photo';
  export default {
    name: 'App',
    components: {
      Photo,
    },
    data() {
      return {
        lightboxIndex: -1,
        photos: [
          require('@/images/Bierpinsel.jpg'),
          require('@/images/Brühlsche-Terrasse.jpg'),
          require('@/images/Crimson-Arches.jpg'),
          require('@/images/Grid-2.jpg'),
          require('@/images/Grid.jpg'),
          require('@/images/Hang-in-There.jpg'),
          require('@/images/Kaleidoscope.jpg'),
          require('@/images/Lines.jpg'),
          require('@/images/Plates.jpg'),
          require('@/images/Sky-Lilies.jpg'),
          require('@/images/Still-life.jpg'),
          require('@/images/Structure.jpg'),
          require('@/images/White-Hallway.jpg'),
          require('@/images/White-House.jpg'),
        ],
      }
    },
    mounted() {
      window.scrollTo(0, 0);
      document.onkeydown = (e) => {
        if (this.lightboxIndex > -1) {
          if (e.keyCode === 37) {
            this.changeImage(-1);
          } else if (e.keyCode === 39) {
            this.changeImage(1);
          }
        }
      }
    },
    methods: {
      openLightbox(index) {
        this.lightboxIndex = index;
      },
      changeImage(delta) {
        this.lightboxIndex += delta;
        if (this.lightboxIndex >= this.photos.length) {
          this.lightboxIndex = 1;
        } else if (this.lightboxIndex <= 0) {
          this.lightboxIndex = this.photos.length - 1;
        }
        this.openLightbox(this.lightboxIndex);
      },
      closeLightbox() {
        this.lightboxIndex = -1;
      },
    }
  };
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: 0;
    user-select: none;
  }
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  body {
    background-color: #151515;
    color: #f0f0f0;
    font-family: Tahoma;
  }
  #app {
    margin: 0 auto;
    position: relative;
    max-width: 1440px;
    width: 100%;
  }

  .lightbox {
    align-items: center;
    background: rgba(#151515, 0.95);
    display: flex;
    height: 100%;
    inset: 0;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transition: opacity .4s ease-in-out;
    width: 100%;
    z-index: 300;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
    .prev,
    .next {
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      cursor: pointer;
      display: -ms-flex;
      display: -webkit-flex;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      top: 0;
      transition: background .2s ease-in-out;
      width: 100px;

      &:hover,
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
      &:after,
      &:after {
        display: block;
        font-size: 30px;
      }
    }
    .prev {
      left: -100px;

      &:after {
        content: "←";
      }
    }
    .next {
      right: -100px;

      &:after {
        content: "→";
      }
    }
    .wrapper {
      max-height: 90vh;
      max-width: calc(90vw - 200px);
      position: relative;
    }
    .current {
      min-height: 10vh;
      min-width: 10vw;

      img {
        display: block;
        max-height: 90vh;
        max-width: calc(90vw - 200px);
      }
    }
  }
  @media (max-width: 1024px) {
    .lightbox {
      .prev,
      .next {
        width: 50px;
      }
      .prev {
        left: -50px;
      }
      .next {
        right: -50px;
      }
      .wrapper {
        max-height: 90vh;
        max-width: calc(90vw - 100px);
      }
      .current {
        img {
          max-height: 90vh;
          max-width: calc(90vw - 100px);
        }
      }
    }
  }
  @media (max-width: 500px) {
    .lightbox {
      .prev,
      .next {
        height: 50px;
        width: 100%;
      }
      .prev {
        left: 0;
        top: -50px;
      }
      .prev:after {
        content: "↑";
      }
      .next {
        bottom: -50px;
        right: 0;
        top: auto;
      }
      .next:after {
        content: "↓";
      }
      .wrapper {
        max-height: calc(90vh - 100px);
        max-width: 90vw;
      }
      .current {
        img {
          max-height: calc(90vh - 100px);
          max-width: 90vw;
        }
      }
    }
  }
  .photos {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 15px 130px 15px;
    position: relative;
    z-index: 100;
  }
  .fader {
    background: linear-gradient(to bottom, rgba(#151515, 0), #151515);
    bottom: 0;
    height: 100px;
    left: 0;
    pointer-events: none;
    position: fixed;
    width: 100vw;
    z-index: 200;
  }
</style>
