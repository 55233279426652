<template>
  <div
    class="photo-wrapper"
    ref="photoWrapper"
  >
    <div
      class="photo"
      :class="{
        loaded: isLoaded,
      }"
      @click="clickCallback"
      :style="{
        backgroundImage: isLoaded ? `url(${photo})` : 'none',
      }"
    />
  </div>
</template>

<script>
  export default {
    name: 'App',
    props: {
      photo: {
        type: String,
        default: '',
        required: true,
      },
      clickCallback: {
        type: Function,
        default: () => {},
        required: true,
      }
    },
    data() {
      return {
        isLoaded: false,
      };
    },
    mounted() {
      const img = new Image();
      img.onload = () => {
        this.isLoaded = true;
      }
      img.src = this.photo;

      this.$refs.photoWrapper.addEventListener('animationiteration', () => {
        if (this.isLoaded) {
          this.$refs.photoWrapper.classList.add('loaded');
        }
      });
    },
  };
</script>

<style lang="scss">
  $photoMargin: 15px;
  $perRow: 5;
  $widthPercentage: 100% / $perRow;
  $perRowTablet: 4;
  $widthPercentageTablet: 100% / $perRowTablet;
  $perRowPhone: 2;
  $widthPercentagePhone: 100% / $perRowPhone;

  .photo-wrapper {
    animation: pulse 1s ease-in-out forwards infinite;
    aspect-ratio: 1;
    background-color: #000;
    border-radius: 50%;
    filter: grayscale(1);
    margin: 0 $photoMargin #{$photoMargin * -1} $photoMargin;
    overflow: hidden;
    opacity: 0.5;
    transform: scale(0.1);
    transition: filter .3s ease-in-out, opacity .3s ease-in-out, transform .3s ease-in-out;
    width: calc(#{$widthPercentage} - #{$photoMargin * 2});

    &:nth-child(2n) {
      animation-delay: .5s;
    }
    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }
    &.loaded {
      animation: grow .5s ease-in-out forwards;
    }
    @for $i from 0 through 10 {
      &:nth-child(#{($perRow + 1) + ($i * (($perRow * 2) - 1))}) {
        margin-left: calc(#{$widthPercentage / 2} + #{$photoMargin});
      }
    }
  }
  @media (max-width: 1024px) {
    .photo-wrapper {
      width: calc(#{$widthPercentageTablet} - #{$photoMargin * 2});

      @for $i from 0 through 10 {
        &:nth-child(#{($perRow + 1) + ($i * (($perRow * 2) - 1))}) {
          margin-left: $photoMargin;
        }
        &:nth-child(#{($perRowTablet + 1) + ($i * (($perRowTablet * 2) - 1))}) {
          margin-left: calc(#{$widthPercentageTablet / 2} + #{$photoMargin});
        }
      }
    }
  }
  @media (max-width: 500px) {
    .photo-wrapper {
      width: calc(#{$widthPercentagePhone} - #{$photoMargin * 2});

      @for $i from 0 through 10 {
        &:nth-child(#{($perRow + 1) + ($i * (($perRow * 2) - 1))}),
        &:nth-child(#{($perRowTablet + 1) + ($i * (($perRowTablet * 2) - 1))}) {
          margin-left: $photoMargin;
        }
        &:nth-child(#{($perRowPhone + 1) + ($i * (($perRowPhone * 2) - 1))}) {
          margin-left: calc(#{$widthPercentagePhone / 2} + #{$photoMargin});
        }
      }
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.1);
    }
    50% {
      transform: scale(0.3);
    }
    100% {
      transform: scale(0.1);
    }
  }
  @keyframes grow {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .photo {
    aspect-ratio: 1;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    height: 100%;
    opacity: 0;
    transition: opacity 1s .5s ease-in-out;
    width: 100%;

    &.loaded {
      opacity: 1;
    }
  }
</style>
